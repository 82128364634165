import React, { useState, useRef } from "react";
import "./Header.scss";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Logo from "../../assets/images/logo/Claimtec-logo.png";
import { RiDashboard3Line } from "react-icons/ri";
import { FaBell, FaUser } from "react-icons/fa";
import { PiSignOutBold } from "react-icons/pi";
import useConfig from "../../hooks/useConfig";
import * as Storage from "../../store/LocalStorage";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FaChevronDown } from "react-icons/fa";

export default function Header() {
  const [selectedOption, setSelectedOption] = useState(
    localStorage.getItem("selectedOption") || "iwyze"
  );
  const location = useLocation();
  const config = useConfig();
  const navigate = useNavigate();
  const { logout, admin } = useAuth();

  const onLogout = () => {
    logout();
    Storage.remove(config.sessionKey);
    console.log("terminal Cleared");
  };

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    localStorage.setItem("selectedOption", selectedValue);
    setSelectedOption(selectedValue);

    if (selectedValue === "iwyze") {
      navigate("/operations");
    } else if (selectedValue === "cash_payments") {
      navigate("/cash-payments");
    }
  };

  const getActiveClass = (path) => {
    return location.pathname === path ? "active" : "";
  };

  const headerLinks =
    selectedOption === "iwyze"
      ? [
          { label: "Operations", path: "/operations" },
          { label: "Customers", path: "/customers" },
          { label: "Templates", path: "/templates" },
        ]
      : [
          { label: "Cash Payments", path: "/cash-payments" },
          { label: "Templates", path: "/templates" },
        ];

  return (
    <div className="header-main">
      <div className="left-header">
        <div className="logo" onClick={() => navigate("/")}>
          <img src={Logo} alt="logo"/>
       
        </div>

        <Select
          value={selectedOption}
          onChange={handleChange}
          className="select-box-main"
          style={{
            color: "white",
          }}
          IconComponent={() => (
            <FaChevronDown style={{ color: "white", marginRight: ".5rem" }} />
          )}
        >
          <MenuItem value="iwyze">iWyze</MenuItem>
          <MenuItem value="cash_payments">Cash Payments</MenuItem>
        </Select>
      </div>
      <div className="right-header">
        <div className="left-links">
          <ul>
            {headerLinks.map((link, index) => (
              <li
                key={index}
                className={`operation-class ${getActiveClass(link.path)}`}
                onClick={() => navigate(link.path)}
              >
                <p>{link.label}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className="right-links">
          <ul>
            <li>
              <FaBell size={20} />
            </li>
            <li style={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
              <FaUser /> {admin.firstName}
            </li>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: ".5rem",
                cursor: "pointer",
              }}
              onClick={() => onLogout()}
            >
              <PiSignOutBold size={22} />
              Sign Out
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
